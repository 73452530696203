import moment from "moment";
import {
  BILLING_ENTITY_TYPE_INDIVIDUAL,
} from "@/constants/billingInformations";

export default {
  type: "sales-deliveries",
  expiration_time: moment().add(30, "days").format("YYYY-MM-DD"),
  code: null,
  excerpt: "",
  discounts: [],
  relationshipNames: ["recipient", "organization", "allowedLocations", "salesOrder"],
  recipient: {
    type: 'customers',
    id: null,
  },
  organization: {
    type: "organizations",
    id: null,
  },
  salesOrder: {
    type: "sales-orders",
    id: null,
  },
  items: [],
  allowedLocations: [],

  delivery_entity_type: BILLING_ENTITY_TYPE_INDIVIDUAL,
  delivery_company_name: "N/A",
  delivery_firstname: "N/A",
  delivery_lastname: "N/A",
  delivery_country: "Canada",
  delivery_state: "Quebec",
  delivery_city: "N/A",
  delivery_zipcode: "N/A",
  delivery_address: "N/A",
  delivery_email: "N/A",
};
